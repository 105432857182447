import DfStore from "@/vue/domain/store/df-store";
import Utils from "@/vue/infrastructure/utils/helpers";
import { Component, Vue, Watch } from "vue-property-decorator";
import VueBarcode from "vue-barcode";
import { IMaskDirective } from "vue-imask";
import validbarcode from "barcode-validator";
import DfBrand from "@/vue/domain/brand/df-brand";

const DfSectionLinksComponent = () => Utils.externalComponent2("df-section-links");
const DfStoreCardComponent = () => Utils.externalComponent2("df-store-card");
const DfHeaderMobileComponent = () => Utils.externalComponent2("df-header-mobile");

@Component({
  components: {
    DfHeaderMobile: DfHeaderMobileComponent,
    DfFooter: () => Utils.externalComponent2("df-footer"),
    DfStoreCard: DfStoreCardComponent,
    DfSectionLinks: DfSectionLinksComponent,
    VueBarcode,
  },
  directives: {
    imask: IMaskDirective,
  },
  filters: {
    cardStoreInfo(currentStore: DfStore): string {
      let storeInfo = "";
      if (currentStore != null) {
        storeInfo = storeInfo.concat(currentStore.postalCode != null ? ` ${currentStore.postalCode}` : "");
        storeInfo = storeInfo.concat(currentStore.city != null ? ` ${currentStore.city}` : "");
        storeInfo = storeInfo.concat(currentStore.province != null ? ` (${currentStore.province.code})` : "");
      }
      return storeInfo.trim();
    },
  },
})
export default class DfPageClubCardComponent extends Vue {
  private clubCardNumber: string = null;
  private clubCardNumberStored: string = null;
  private clubCardNumberError = false;

  private mask: any = {
    mask: "0000000000000",
    lazy: false,
  };

  get brand(): DfBrand {
    return this.$store.getters.brand;
  }

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get clubCardLink(): string {
    return Utils.getPropertyValue(this.brand, Utils.PROPERTY_CARD_CLUB_MY_AREA_URL, "STRING");
  }

  get clubCardLabel(): any {
    return Utils.getPropertyValue(this.brand, Utils.PROPERTY_CARD_CLUB_MY_AREA_LABEL, "TEXT");
  }

  beforeMount() {
    this.clubCardNumber = Utils.localStorageGetItem("smt-digitalflyer.df-club-card-code");
    this.clubCardNumberStored = Utils.localStorageGetItem("smt-digitalflyer.df-club-card-code");

    if (this.clubCardNumber && this.clubCardNumber.replace(/_/g, "").length === 13) {
      this.clubCardNumberError = !this.cardNumberIsValid();
    }
  }

  @Watch("clubCardNumber")
  private changeClubCardNumber() {
    if (this.clubCardNumber && this.clubCardNumber.replace(/_/g, "").length < 13) {
      this.clubCardNumberError = false;
    }
  }

  private saveClubCardNumber() {
    this.clubCardNumberStored = this.clubCardNumber;
    if (this.clubCardNumberStored) {
      Utils.localStorageSetItem("smt-digitalflyer.df-club-card-code", this.clubCardNumberStored);
    } else {
      Utils.localStorageRemoveItem("smt-digitalflyer.df-club-card-code");
    }
  }

  private goToStorePage(store: DfStore) {
    this.$router.push({ name: "store", params: { storeAlias: store.alias } }).catch((err) => err);
  }

  private openMap() {
    window.open(`${Utils.GOOGLE_MAP_URL}${Utils.storeGoogleMapsCoordinates(this.currentStore)}`, "_blank");
  }

  private resetClubCardNumber() {
    this.clubCardNumber = null;
  }

  private restoreClubCardNumber() {
    this.clubCardNumber = this.clubCardNumberStored;
  }

  private onAccept(event: any) {
    const maskRef = event.detail;
    this.clubCardNumber = maskRef.value;
  }

  private onComplete(event: any) {
    this.clubCardNumberError = !this.cardNumberIsValid();
  }

  private cardNumberIsValid(): boolean {
    return validbarcode(this.clubCardNumber);
  }

  private cardNumberStoredIsValid(): boolean {
    return validbarcode(this.clubCardNumberStored);
  }

  private cardNumberIsChanging(): boolean {
    const newValue: string = this.clubCardNumber ? this.clubCardNumber.replace(/_/g, "") : "";
    const oldValue: string = this.clubCardNumberStored ? this.clubCardNumberStored.replace(/_/g, "") : "";

    return newValue !== oldValue;
  }
}
